import { useEffect, useState } from "react";
import {
  AiFillInfoCircle,
  AiOutlineClose,
  AiOutlineInfo,
  AiOutlineMenu,
} from "react-icons/ai";
import { BsInfo, BsInfoCircle } from "react-icons/bs";
import { FaInfoCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import RegistrationDescription from "./registrationDescription";
import { AnimatePresence, motion } from "framer-motion";

const Navbar = () => {
  const [isScrolling, setIsScrolling] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      if (window.scrollY > 100) {
        setIsScrolling(true);
      } else {
        setIsScrolling(false);
      }
    });
  }, []);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 5000);
  }, []);
  return (
    <div>
      <AnimatePresence>
        {show && (
          <motion.div
            exit={{
              scale: 0,
              opacity: 0,
              transition: { duration: 0.2, ease: "linear" },
            }}
            animate={{
              scale: 1,
              opacity: 1,
              transition: { duration: 0.2, ease: "linear" },
            }}
            initial={{ scale: 0, opacity: 0 }}
            className="fixed inset-0 bg-lightDark m-4 p-5 rounded-xl z-50 block md:hidden"
          >
            <div className="flex justify-end mb-5">
              <AiOutlineClose
                onClick={() => {
                  setShow(false);
                }}
                className="text-xl text-opacity-40"
              />
            </div>
            <RegistrationDescription />
          </motion.div>
        )}
      </AnimatePresence>
      <div className={`fixed w-full  bg-dark  z-40  `}>
        <div className="flex justify-between items-center w-11/12 2xl:w-10/12 mx-auto py-4">
          <img
            onClick={() => {
              navigate("/");
            }}
            className="h-8 md:h-10 cursor-pointer"
            src="/logo.svg"
          />

          <div
            onClick={() => {
              setShow(true);
            }}
          >
            <BsInfoCircle className=" block md:hidden text-2xl" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
