import { useContext, useState } from "react";
import { BsLightbulb } from "react-icons/bs";
import { Outlet, useNavigate } from "react-router-dom";
import Navbar from "../components/navbar";
import { ToastContainer } from "react-toastify";

const Mainlayout = () => {
  const [theme, setTheme] = useState("dark");
  const navigate = useNavigate();
  return (
    <div className={`${theme} overflow-hidden`}>
      <div className=" min-h-screen dark:bg-dark dark:text-white text-base ">
        {/* <ToastContainer position="top-right" /> */}
        <Navbar />
        <Outlet />
      </div>
    </div>
  );
};

export default Mainlayout;
